.oicon{
    /* width: 2vw;
    height: 4vh; */
    width: 30px;
    height: 30px;
}
.r-icon{
    margin-left: 3.3vw;
    margin-top: -0.4vh;
    margin-bottom: 2vh;
    display: flex;
}
.f-icon{
    padding: 5px;
    font-size: 1.5rem;
}
.s-icon{
    padding: 5px;
    font-size: 1.5rem;
}
.t-icon{
    padding: 5px;
    font-size: 1.5rem;
}
.hoverfIcon, .hoversIcon, .hovertIcon{
    display: none;
    width: max-content;
    font-size: 0.6rem;
}
.f-icon:hover{
    border-radius: 10px;
   background-color: rgba(146, 146, 146, 0.304);
  

}
.f-icon:hover + .hoverfIcon {
    display: block;
}
.s-icon:hover{
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0.304);
}
.s-icon:hover + .hoversIcon{
    display: block;
}
.t-icon:hover{
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0.304);
}
.t-icon:hover + .hovertIcon{
    display: block;
}
