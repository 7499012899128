.bav{
    width: 30px;
    height: 30px;
}

@media screen and (max-width: 600px){
    .bav{
        width: 15px;
        height: 15px;
    }
}