.glass{
    /* background: rgba(43,67,137,0.15);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px); */
    border: 1px solid white;
    padding: 1.5vh;
    width: max-content;
    margin-bottom: 10px;
    border-radius: 10px;
    margin-right: 1vw;
    /* background: transparent !important; */
    font-size: 1.1rem;
  
}
.mainbox{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.528);
    margin-top:10vh;
    border-radius: 5px;
    padding: 10vh;
    width: max-content;
    position: relative;
    height: max-content;
    background-color: #fff;
}

.floral-design {
    position: absolute;
    top: 0;
    right: 0; 

  }

  #please{
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: whitesmoke !important;
    padding: 2vh;
    border: none;
}

  .imgg{
    height: 100%;
    width: 10%;
    opacity: 0.18;
  }
  .svg{
    width: auto;
    height: 100%;
  }
  
  .floral-design img {
    width: 100%;
    height: fit-content;
    object-fit: cover;
   
  }
.form{
margin-left: 20vw;
}
.brandname{
    margin-left: 30%;
    margin-bottom: 6vh;
}
.questions{
   
    width: 85%;
    display: grid;
    grid-template-columns: 30vw 30vw;


}


.answers{
    border: none;
    opacity: 0.4;
    padding: 0 10px; /* Add padding to fix text alignment */
    width: 25vw;
    background-color: transparent;
    border-bottom: 1px black solid;
  }
  
  ul {
    list-style: none;
  }



  .shapes {
    margin-left: 60%;
    margin-top: -40%;
    position: absolute;
    width: 44.8px;
    height: 44.8px;
    color: #1363c6;
    position: relative;
  }
  
  .shapes:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: conic-gradient(from 0deg,  #0000, currentColor 1deg 120deg,#0000 121deg) top right,
           conic-gradient(from 120deg,#0000, currentColor 1deg 120deg,#0000 121deg) bottom,
           conic-gradient(from 240deg,#0000, currentColor 1deg 120deg,#0000 121deg) top left;
    background-size: 44.8px 44.8px;
    background-repeat: no-repeat;
    animation: shapes-r0t0iomd 2s infinite cubic-bezier(0.3,1,0,1);
  }
  
  @keyframes shapes-r0t0iomd {
    33% {
       inset: -9px;
       transform: rotate(0deg);
    }
  
    66% {
       inset: -9px;
       transform: rotate(180deg);
    }
  
    100% {
       inset: 0;
       transform: rotate(180deg);
    }
  }
  

  /*     display: flex;
  width: 15vw !important;
  flex-wrap: wrap !important;
   */


   @media (max-width: 600px){
    .questions{
      display: flex;
      flex-direction: column;
    }
    .mainbox{
     height: max-content;
    }
    #pills-home{
      display: flex;
      flex-direction: column;
    }
    #pills-profile{
      display: flex;
      flex-direction: column;
    }
  
    #pills-tab{
      margin-top: 15vh;
    }
   }